import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import RouteWithLayout from './components/RouteWithLayout';
import MainLayout from './layouts/Main';

const HomeView = React.lazy(() => import('./views/Home'));

function App() {
  return (
    <Suspense fallback={<p>Loading...</p>}>
      <Router>
        <Switch>
          <RouteWithLayout element={HomeView} layout={MainLayout} path="/" />
        </Switch>
      </Router>
    </Suspense>
  );
}

export default App;
