import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteWithLayout = (props) => {
  const { layout: Layout, element: Element, ...rest } = props;
  return (
    <Route {...rest}>
      <Layout>
        <Element />
      </Layout>
    </Route>
  );
};

RouteWithLayout.propTypes = {
  element: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
};

export default RouteWithLayout;
